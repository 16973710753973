<template>
  <div>
    <CompanyInfo
      :images="images"
      :companyBrandColour="companyBrandColour"
      :companyLogo="companyLogo"
    >
      <template #companyName>Amgen</template>
      <template #date>2019 - Ongoing</template>
      <template #content>
        <ul class="dashed">
          <li>
            We supplied our moderated interactive iPad voting system to help educate
            healthcare professionals about Amgen’s biosimilars portfolio at a series of
            European congresses.
          </li>
          <li>
            Delegates were engaged in a fun effective way as they interacted with
            pre-produced media content using our individual touchscreens to answer sets of
            questions against the clock.
          </li>
          <li>
            Information and statistics were sent back to Amgen based on the delegate's
            responses for later statistical analysis.
          </li>
          <li>
            Our interactive system was also used at Amgen's annual summit where we
            supported the client's end of year analysis.
          </li>
          <li>
            We have recently helped to deliver a virtual end of year online summit
            supporting global Amgen teams, moderating their important results to help them
            reflect on their business successes.
          </li>
        </ul>
      </template>
    </CompanyInfo>
  </div>
</template>

<script>
import CompanyInfo from "@/components/Portfolio/CompanyInfo.vue";
export default {
  components: { CompanyInfo },
  name: 'AmgenItem',
  data() {
    return {
      companyBrandColour: "#3260A2",
      companyLogo: require("@/assets/images/portfolio/amgen-logo.svg"),
      images: [
        {
          id: 0,
          responsiveImages: require("@/assets/images/portfolio/amgen/1.jpg"),
          position: { x: 40, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0)",
          pictureDetails: "EUROPEAN SOCIETY OF MEDICAL ONCOLOGY (2019) BARCELONA",
        },
        {
          id: 1,
          responsiveImages: require("@/assets/images/portfolio/amgen/2.jpg"),
          position: { x: 47, y: 13 },
          backgroundImageTint: "rgba(0, 0, 0, 0)",
          pictureDetails: "EUROPEAN CROHN’S AND COLITIS ORGANISATION 2020 VIENNA",
        },
        {
          id: 2,
          responsiveImages: require("@/assets/images/portfolio/amgen/3.jpg"),
          position: { x: 55, y: 40 },
          backgroundImageTint: "rgba(0, 0, 0, 0.2)",
          pictureDetails: "EUROPEAN SOCIETY OF MEDICAL ONCOLOGY (2019) BARCELONA",
        },
        {
          id: 3,
          responsiveImages: require("@/assets/images/portfolio/amgen/4.jpg"),
          position: { x: 43, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.3)",
          pictureDetails: "EUROPEAN CROHN’S AND COLITIS ORGANISATION 2020 VIENNA",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
